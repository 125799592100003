import { createSlice } from '@reduxjs/toolkit';
import {
  getWatchlistAction,
  createWatchlistAction,
  updateWatchlistAction,
  deleteWatchlistAction,
} from './watchlistThunk';

const initialState = {
  watchlist: {
    data: [],
    loading: false,
    error: null,
  },
  createStatus: {
    loading: false,
    success: false,
    error: null,
  },
  updateStatus: {
    loading: false,
    success: false,
    error: null,
  },
  deleteStatus: {
    loading: false,
    success: false,
    error: null,
  },
};
const watchlistSlice = createSlice({
  name: 'watchlist',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get Watchlist
    builder
      .addCase(getWatchlistAction.pending, (state) => {
        state.watchlist.loading = true;
        state.watchlist.error = null;
      })
      .addCase(getWatchlistAction.fulfilled, (state, action) => {
        state.watchlist.loading = false;
        state.watchlist.data = action.payload;
      })
      .addCase(getWatchlistAction.rejected, (state, action) => {
        state.watchlist.loading = false;
        state.watchlist.error = action.payload;
      });

    // Create Watchlist
    builder
      .addCase(createWatchlistAction.pending, (state) => {
        state.createStatus.loading = true;
        state.createStatus.success = false;
        state.createStatus.error = null;
      })
      .addCase(createWatchlistAction.fulfilled, (state) => {
        state.createStatus.loading = false;
        state.createStatus.success = true;
      })
      .addCase(createWatchlistAction.rejected, (state, action) => {
        state.createStatus.loading = false;
        state.createStatus.error = action.payload;
      });
    builder
      .addCase(updateWatchlistAction.pending, (state) => {
        state.updateStatus.loading = true;
        state.updateStatus.error = null;
      })
      .addCase(updateWatchlistAction.fulfilled, (state, action) => {
        state.updateStatus.loading = false;
        state.updateStatus.success = true;

        // Update watchlist data in the state
        state.watchlist.data = state.watchlist.data.map((list) => {
          const updatedList = action.payload.find((item) => item.id === list.id);
          return updatedList ? { ...list, ...updatedList } : list;
        });
      })
      .addCase(updateWatchlistAction.rejected, (state, action) => {
        state.updateStatus.loading = false;
        state.updateStatus.error = action.payload;
      });
    builder
      .addCase(deleteWatchlistAction.pending, (state) => {
        state.deleteStatus.loading = true;
        state.deleteStatus.error = null;
      })
      .addCase(deleteWatchlistAction.fulfilled, (state, action) => {
        state.deleteStatus.loading = false;
        state.deleteStatus.success = true;
        state.watchlist.data = state.watchlist.data.filter(
          (list) => list.id !== action.payload.listId,
        );
        console.log(`✅ Watchlist ${action.payload.listId} removed from Redux state`);
      })
      .addCase(deleteWatchlistAction.rejected, (state, action) => {
        state.deleteStatus.loading = false;
        state.deleteStatus.error = action.payload;
      });
  },
});

export default watchlistSlice.reducer;
