/* eslint-disable no-restricted-globals */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './index.css';
import { widget } from '../../charting_library';
import { getNewsAction } from '../../store/news/newsThunk';
import { createWatchlistAction, getWatchlistAction } from '../../store/watchlist/watchlistThunk';

function getLanguageFromURL() {
  const regex = new RegExp('[\\?&]lang=([^&#]*)');
  const results = regex.exec(window.location.search);
  return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export const TVChartContainer = (props) => {
  const chartContainerRef = useRef();
  const newsState = useSelector((state) => state.newsSlice.news.data);
  const dispatch = useDispatch();

  const { data: watchlistData, loading, error } = useSelector((state) => state.watchlist);

  // Fetch the initial watchlist from the API when the widget is ready
  useEffect(() => {
    dispatch(getWatchlistAction());
  }, [dispatch]);

  useEffect(() => {
    if (!loading && watchlistData?.length > 0) {
      console.log('Watchlist data:', watchlistData);
    }
  }, [loading, watchlistData]);

  const [currentSymbol, setCurrentSymbol] = useState('');

  useEffect(() => {
    console.log('Dispatching getNewsAction');
    dispatch(getNewsAction(props.symbol));
    console.log('Dispatching getNewsAction', newsState);
  }, [dispatch, props.symbol]);

  useEffect(() => {
    const widgetOptions = {
      symbol: props.symbol,
      datafeed: new window.Datafeeds.UDFCompatibleDatafeed(props.datafeedUrl, props.headers),
      container: chartContainerRef.current,
      library_path: '/charting_library/',
      locale: getLanguageFromURL() || 'en',
      disabled_features: props.disabled_features,
      enabled_features: props.enabled_features,
      // enabled_features: [
      //   // Keeping for future reference

      //   'header_saveload',
      //   'study_templates',
      //   'confirm_overwrite_if_chart_layout_with_name_exists',
      //   'has_intraday',
      //   'seconds_resolution',
      //   'header_screenshot',
      //   'header_settings',
      //   'dom_widget',
      //   'widgetbar_watchlist',
      //   'display_market_status',
      //   'show_symbol_logos',
      //   'show_exchange_logos',
      //   'show_symbol_logo_in_legend',
      //   'show_symbol_logo_in_account_manager',
      // ],
      supported_resolutions: ['1', '5', '15', '30', '60', '1D', '1W', '1M'],
      timezone: 'Asia/Colombo',
      charts_storage_url: props.chartsStorageUrl,
      charts_storage_api_version: props.chartsStorageApiVersion,
      client_id: props.clientId,
      user_id: props.userId,
      fullscreen: props.fullscreen,
      autosize: props.autosize,
      studies_overrides: props.studiesOverrides,
      widgetbar: props.widgetbar,
      debug: true,
      rss_news_feed: {
        default: [
          {
            url: `https://asec.dev.arimac.digital/atrad-svc/api/news/rss?symbol=${currentSymbol}`,
            name: 'Market news',
          },
        ],
      },
      drawings_access: props.drawings_access,
      studies_access: props.studies_access,
      theme: props.theme,
      broker_factory: function (host) {
        return new window.Brokers.BrokerSample(host, widgetOptions.datafeed);
      },
      broker_config: {
        configFlags: {
          supportNativeReversePosition: true,
          supportPlaceOrderPreview: true,
          supportClosePosition: true,
          supportPLUpdate: true,
          supportLevel2Data: true,
          showQuantityInsteadOfAmount: true,
          supportEditAmount: true,
          supportOrderBrackets: true,
          supportMarketBrackets: true,
          supportPositionBrackets: true,
          supportPositions: true,
          supportDOM: true,
        },
        durations: [
          { name: 'DAY', value: 'DAY' },
          { name: 'GTC', value: 'GTC' },
          // { name: 'GTD', value: 'GTD' }, advised by @Shamindri Perera to not display this option as implementation has not been done.
        ],
      },
    };

    const tvWidget = new widget(widgetOptions);

    const watchlistApiRef = { current: null }; // Store watchlistApi in a ref

    const handleCreateWatchlist = (watchlistApi, newListName, symbols) => {
      const payload = {
        listName: newListName,
        symbols,
      };
      dispatch(createWatchlistAction(payload)).then((response) => {
        if (response.meta.requestStatus === 'fulfilled') {
          console.log('Watchlist created successfully:', response.payload);
          // Use the watchlist API to update the chart widget
          watchlistApi.createList(newListName, symbols);
        } else {
          console.error('Error creating watchlist:', response.error);
        }
      });
    };

    tvWidget.onChartReady(async () => {
      const watchlistApi = await tvWidget.watchList();
      dispatch(getWatchlistAction());
      console.log('111111111 dskjnfskdjnfsdjnf', watchlistData);

      // Step 1: Fetch initial watchlist
      // const response = await fetch('/api/getWatchlist');
      // const initialWatchlist = await response.json();

      // Example of the returned structure from API:
      // {
      //    "title": "My Watchlist",
      //    "symbols": ["AAPL", "GOOG", "TSLA"]
      // }

      // Step 2: Create or update the watchlist with the fetched data
      // const watchlistTitle = initialWatchlist.title || 'Default Watchlist';
      // const symbols = initialWatchlist.symbols || [];

      // Create a new watchlist with the symbols
      // const newWatchlist = watchlistApi.createList(watchlistTitle, symbols);

      // watchlistApi.onListAdded().subscribe(null, (newList) => {
      //   console.log('New list added:', newList);
      //   console.log('dskjnfskdjnfsdjnf', watchlistData);
      //   // dispatch(getWatchlistAction()).then(() => {
      //   //   console.log('Updated watchlist data:', watchlistData);
      //   // });
      // });
      watchlistApi.onListAdded().subscribe(null, (newList) => {
        console.log('New list added:', newList);

        // Prepare payload with just the list name
        const newWatchlistPayload = {
          listName: newList, // Use the newList as the listName
          symbols: [], // Empty symbols array
        };

        // Dispatch the createWatchlistAction
        dispatch(createWatchlistAction(newWatchlistPayload)).then((response) => {
          if (response.meta.requestStatus === 'fulfilled') {
            console.log('Successfully created watchlist with name:', newList);
          } else {
            console.error('Failed to create watchlist:', response.error);
          }
        });
      });

      watchlistApi.onActiveListChanged().subscribe(null, () => {
        console.log('Active list changed');
        const activeListId = watchlistApi.getActiveListId();
        const currentListItems = watchlistApi.getList(activeListId);
        console.log('Active list items:', currentListItems);
      });
      watchlistApi.onListChanged().subscribe(null, (listId) => {
        console.log('List changed:', listId);
        const updatedList = watchlistApi.getList(listId);
        console.log('Updated list:', updatedList);
      });
      watchlistApi.onListRenamed().subscribe(null, (a) => {
        console.log('List changed:', a);
        // const updatedList = watchlistApi.getList(listId);
        // console.log('Updated list:', updatedList);
      });
    });

    tvWidget.onChartReady(async () => {
      // const watchlistApi = await tvWidget.i;
      tvWidget.subscribe('study_event', (entityId, studyEventType, entity) => {
        console.log('Indicator added 1111:', entityId, studyEventType);
        console.log('Indicator added 1111:', entity);

        // Here, you could also set up a custom handler for user clicks on the overlay element.
      });
    });

    return () => {
      tvWidget.remove();
    };
  }, [
    props.symbol,
    currentSymbol,
    props.datafeedUrl,
    props.headers,
    props.disabled_features,
    props.chartsStorageUrl,
    props.chartsStorageApiVersion,
    props.clientId,
    props.userId,
    props.fullscreen,
    props.autosize,
    props.studiesOverrides,
    props.widgetbar,
    props.drawings_access,
    props.studies_access,
    props.theme,
    dispatch,
    watchlistData,
    props.enabled_features,
  ]);

  return <div ref={chartContainerRef} className={'TVChartContainer'} />;
};
