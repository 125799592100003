/**
 * The `subscriptionMapper` function takes in a data object and maps it to a new object structure with
 * packages and features.
 * @param data - The `data` parameter is an object that contains information about subscription tiers
 * and features. It has the following structure:
 * @returns The function `subscriptionMapper` returns an object with two properties: `packages` and
 * `features`. The `packages` property contains an array of tiers, and the `features` property contains
 * an array of objects, each representing a feature. Each feature object has two properties: `name`,
 * which represents the name of the feature, and `data`, which is an array of boolean values indicating
 * whether the
 */
export const subscriptionFeaturesMapper = (data) => {
  const featureCategories = {
    drawingTools: 'Drawing Tools',
    indicators: 'Indicators',
    // otherFeatures: 'Other Features',
  };
  const output = {
    packages: data.tiers,
    features: [],
  };
  Object.keys(data).forEach((category) => {
    if (category === 'indicators') {
      const Indicators = { title: 'Indicators', features: [], titleText: data.indicatorInfo };
      if (typeof data[category] === 'object') {
        Object.keys(data[category]).forEach((key) => {
          const feature = data[category][key];
          const featureData = Array(data.tiers.length).fill(false);
          feature?.enabledTiers?.forEach((tier) => {
            const tierIndex = data.tiers.indexOf(tier);
            featureData[tierIndex] = true;
          });

          Indicators.features.push({
            name: feature.feature,
            category: featureCategories[category],
            data: featureData,
          });
        });
        output.features.push(Indicators);
      }
    }
    if (category === 'otherFeatures') {
      const OtherFeatures = { title: 'Other Features', features: [], titleText: '' };
      if (typeof data[category] === 'object') {
        Object.keys(data[category]).forEach((key) => {
          const feature = data[category][key];
          const featureData = Array(data.tiers.length).fill(false);
          feature?.enabledTiers?.forEach((tier) => {
            const tierIndex = data.tiers.indexOf(tier);
            featureData[tierIndex] = true;
          });
          OtherFeatures.features.push({
            name: feature.feature,
            category: featureCategories[category],
            data: featureData,
          });
        });
        output.features.push(OtherFeatures);
      }
    }

    if (category === `drawingTools`) {
      const DrawingTools = {
        title: 'Drawing Tools',
        features: [],
        titleText: data.drawingToolInfo,
      };
      if (typeof data[category] === 'object') {
        Object.keys(data[category]).forEach((key) => {
          const drawingFeature = data[category][key];
          const featureList = [];
          Object.keys(drawingFeature).forEach((drawingFeatureKey) => {
            const feature = drawingFeature[drawingFeatureKey];
            const featureData = Array(data.tiers.length).fill(false);
            feature?.enabledTiers?.forEach((tier) => {
              const tierIndex = data.tiers.indexOf(tier);
              featureData[tierIndex] = true;
            });
            featureList.push({
              name: feature.feature,
              category: featureCategories[category],
              data: featureData,
            });
          });
          if (featureList.length !== 0) {
            DrawingTools.features.push({
              title: key.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^./, function (str) {
                return str.toUpperCase();
              }),
              features: featureList,
            });
          }
        });
        if (DrawingTools.features.length !== 0) {
          output.features.push(DrawingTools);
        }
      }
    }
  });

  return output;
};
// export const subscriptionFeaturesMapper = (data) => {
//   const featureCategories = {
//     drawingTools: 'Drawing Tools',
//     indicators: 'Indicators',
//     otherFeatures: 'Other Features',
//   };
//   const output = {
//     packages: data.tiers,
//     features: [],
//   };
//   Object.keys(data).forEach((category) => {
//     if (category !== 'tiers' && category !== 'drawingToolInfo' && category !== 'indicatorInfo') {
//       if (typeof data[category] === 'object') {
//         Object.keys(data[category]).forEach((key) => {
//           const feature = data[category][key];
//           const featureData = Array(data.tiers.length).fill(false);
//           feature?.enabledTiers?.forEach((tier) => {
//             const tierIndex = data.tiers.indexOf(tier);
//             featureData[tierIndex] = true;
//           });
//           output.features.push({
//             name: feature.feature,
//             category: featureCategories[category],
//             data: featureData,
//           });
//         });
//       }
//     }
//   });
//   console.log({ output });
//   return output;
// };

/**
 * The function `subscriptionPlansDataMapper` maps an array of subscription plans data to a new array
 * of formatted subscription plans objects.
 * @param plansData - An array of objects containing subscription plan data. Each object should have
 * the following properties:
 * @returns The function `subscriptionPlansDataMapper` returns an array of objects. Each object in the
 * array represents a subscription plan and has the following properties:
 */

export const subscriptionPlansDataMapper = (plansData) => {
  // TODO  : highlight key should be refer the isPopular key from the BE when it is done code should be updated
  // highlight: isPopular ? isPopular : false,
  // subscriptionContent should be map to items array
  return plansData.map((planData) => {
    const {
      subscriptionId,
      subscriptionTitle,
      subscriptionMonthlyFee,
      subscriptionYearlyFee,
      subscriptionMonthlyDiscount,
      subscriptionYearlyDiscount,
      subscriptionContent,
      isPopular,
      subscriptionTags,
    } = planData;

    return {
      code: subscriptionId,
      name: subscriptionTitle,
      price: {
        monthly: subscriptionMonthlyFee,
        yearly: subscriptionYearlyFee,
      },
      discount: {
        monthly: subscriptionMonthlyDiscount,
        yearly: subscriptionYearlyDiscount,
      },
      highlight: isPopular,
      per: 'per user',
      items: subscriptionContent,
      button: `Continue with ${subscriptionTitle}`,
      subscriptionTag: subscriptionTags,
    };
  });
};
