import './TVChart.css';
import { TVChartContainer } from '../../components/TVChartContainer/index';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getSession, setSession } from '../../helpers/helpers';
import { getTVChartAction } from '../../store/tv-chart-config/tv-chart-configThunk';
import { getAccessToken } from '../../api/lib/auth.api';
import { Link, useNavigate } from 'react-router-dom';
import { logoutAndRedirect } from '../../helpers/utils';
import { getUserProfileDetails } from '../../store/profile/profileThunk';
import back_arrow from '../../assets/pricing/icons/arrow_left.svg';
import DropdownMenu from './DropdownMenu';

const TVChartPage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserProfileDetails());
    dispatch(getTVChartAction());
  }, [dispatch]);

  const headers = {
    Authorization: `Bearer ${getSession('auth_token')}`,
    'Content-Type': 'application/json',
  };

  //  reson: due to expiration of token , we will set the token for every 10 minutes in the trading view
  useEffect(() => {
    const intervalId = setInterval(async () => {
      try {
        const resp = await getAccessToken();
        setSession('auth_token', resp?.data?.access_token);
        setSession('refresh_token', resp?.data?.refresh_token);
        console.log({ resp });
      } catch (error) {
        console.error('Error refreshing access token:', error);
      }
    }, 60000); // 10 minutes in milliseconds

    return () => clearInterval(intervalId); // Clear the interval on component unmount
  }, []);

  const [componentId, setComponentId] = useState('home');

  const chartProps = useSelector((state) => state.tvChartSlice.tvChartConfigsData.data);
  const profileInformation = useSelector((state) => state.profileSlice.userData.profileInformation);
  const lang = useSelector((state) => state.states.lang.data.dashboard);
  const isDarkMode = useSelector((state) => state.theme.isDarkMode);
  const navigate = useNavigate();

  const onLogout = async () => {
    await logoutAndRedirect();
  };

  return (
    <div className='home-page'>
      <div className='dashboard-layout-ribbon'>
        <div style={{ display: 'flex' }}>
          <Link to='/' className='dashboard-layout-ribbon-logo' />
          <button
            tabIndex={0}
            onClick={() => window.location.replace('/dashboard')}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                window.location.replace('/dashboard');
              }
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              background: 'transparent',
              border: 'none',
            }}
          >
            <img src={back_arrow} alt='Check Icon' width='16px' style={{ marginLeft: '30px' }} />
            <p style={{ marginLeft: '5px', fontSize: '12px' }}>Back to Dashboard</p>
          </button>
        </div>

        <div>
          <div className='dashboard-layout-ribbon-profile-details'>
            <div className='checkbox-container'>
              {/* <input
                type='checkbox'
                class='checkbox'
                id='checkbox'
                onChange={() => {
                  setIsDarkMode(!isDarkMode);
                }}
              /> */}
              {/* <label for='checkbox' class='checkbox-label'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  class='icon icon-tabler icon-tabler-moon-stars'
                  width='44'
                  height='44'
                  viewBox='0 0 24 24'
                  stroke-width='1.5'
                  stroke='#ffbf00'
                  fill='none'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                >
                  <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                  <path d='M12 3c.132 0 .263 0 .393 0a7.5 7.5 0 0 0 7.92 12.446a9 9 0 1 1 -8.313 -12.454z' />
                  <path d='M17 4a2 2 0 0 0 2 2a2 2 0 0 0 -2 2a2 2 0 0 0 -2 -2a2 2 0 0 0 2 -2' />
                  <path d='M19 11h2m-1 -1v2' />
                </svg>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  class='icon icon-tabler icon-tabler-brightness-down'
                  width='44'
                  height='44'
                  viewBox='0 0 24 24'
                  stroke-width='1.5'
                  stroke='#ffbf00'
                  fill='none'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                >
                  <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                  <path d='M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0' />
                  <path d='M12 5l0 .01' />
                  <path d='M17 7l0 .01' />
                  <path d='M19 12l0 .01' />
                  <path d='M17 17l0 .01' />
                  <path d='M12 19l0 .01' />
                  <path d='M7 17l0 .01' />
                  <path d='M5 12l0 .01' />
                  <path d='M7 7l0 .01' />
                </svg>
                <span class='ball'></span>
              </label> */}
            </div>
            {profileInformation.profilePictureUrl ? (
              <div
                className='dashboard-layout-profile-image'
                style={{ backgroundImage: `url(${profileInformation.profilePictureUrl})` }}
              />
            ) : (
              <div className='dashboard-layout-profile-image' />
            )}
            <div className='dashboard-layout-profile-name typo-body-v2'>
              {profileInformation?.username}
            </div>

            <DropdownMenu onLogout={onLogout} lang={lang} />
            <div
              className={`dashboard-layout-profile-category ${profileInformation.subscriptionName} typo-info-note`}
              onClick={() => navigate('/pricing')}
            >
              {profileInformation?.subscription?.trialEnabled ? (
                <div>{profileInformation.subscriptionName} Free Trial</div>
              ) : profileInformation.subscriptionName === 'Free' ? (
                <div>Upgrade Your Free Plan</div>
              ) : (
                <div>{profileInformation.subscriptionName} Plan</div>
              )}

              {profileInformation?.subscription?.trialEnabled && (
                <div>{profileInformation?.subscription?.remainingDays} Days Left</div>
              )}
            </div>
          </div>
        </div>
      </div>
      {chartProps && (
        <TVChartContainer
          symbol={chartProps?.symbol}
          interval={chartProps?.interval}
          datafeedUrl={chartProps?.datafeedUrl}
          chartsStorageUrl={chartProps?.chartsStorageUrl}
          chartsStorageApiVersion={chartProps?.chartsStorageApiVersion}
          clientId={chartProps?.clientId}
          userId={chartProps?.userId}
          fullscreen={chartProps?.fullscreen}
          autosize={chartProps?.autosize}
          studiesOverrides={chartProps?.studiesOverrides}
          disabled_features={chartProps?.disabled_features}
          enabled_features={chartProps?.enabled_features}
          widgetbar={chartProps?.widgetbar}
          rssNewsFeed={chartProps?.rssNewsFeed}
          drawings_access={chartProps?.drawings_access}
          studies_access={chartProps?.studies_access}
          headers={headers}
          theme={isDarkMode ? 'dark' : ''}
        />
      )}
    </div>
  );
};

export default TVChartPage;
