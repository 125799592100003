import { apiClient } from '../apiClient';

export const getWatchlist = async () => {
  return await apiClient.get('/api/v1/user/watchlist/getAllWatchLists');
};

export const createWatchlist = async (payload) => {
  return await apiClient.post('/api/v1/user/watchlist', payload);
};

export const updateWatchlist = async (listId, symbols) => {
  console.log('Calling API: updateWatchlist');
  console.log('listId:', listId, 'symbols:', symbols);

  const encodedSymbols = encodeURIComponent(symbols.join(',')); // ✅ Ensure symbols are properly encoded

  return await apiClient.post(
    `/api/v1/user/watchlist/updateWatchlist?listId=${listId}&symbols=${encodedSymbols}`,
  );
};

export const deleteWatchlist = async (listId) => {
  console.log('Calling API: deleteWatchlist for listId:', listId);
  return await apiClient.delete(`/api/v1/user/watchlist/deleteWatchlist?listId=${listId}`);
};
