/* eslint-disable no-restricted-globals */

/**
 * ========================================================================
 * 🎯 TRADINGVIEW CHART CONTAINER COMPONENT - QUICK START GUIDE
 * ========================================================================
 *
 * 🔥 This component integrates TradingView's charting library into the app.
 *
 * 📌 **Key Development Considerations:**
 * 1️⃣ **Data Source:**
 *    - Most props used in this component are fetched from the API:
 *      `https://asecathena.asiasecurities.lk/user-mgt-svc/api/v1/subscription/chart/config`
 *    - If you modify any features here, **you must inform the Backend Team**
 *      so they can add/remove those in the API response.
 *
 * 2️⃣ **Feature Toggles (Development vs. Production):**
 *    - **`enabled_features` and `disabled_features` are hardcoded for development.**
 *    - In **production**, use the API-provided values (`props.enabled_features`, `props.disabled_features`).
 *    - **Before deployment**, comment out the hardcoded list and uncomment the API-driven version.
 *
 * 3️⃣ **Custom Styles & Workarounds:**
 *    - The **remove button (❌) from the watchlist is hidden via injected CSS** inside an `iframe`.
 *    - To **modify this behavior**, check the `setTimeout()` inside `useEffect()`.
 *
 * 4️⃣ **General Best Practices:**
 *    ✅ **Avoid modifying `widgetOptions` directly** – update API settings first.
 *    ✅ **Always check for TradingView API updates** before modifying core settings.
 *    ✅ **Use `console.log` only for debugging** and remove before committing.
 *    ✅ **Ensure that Redux state (`watchlistData`, etc.) is kept in sync** with TradingView.
 *
 * 🛠 **How to Update & Maintain:**
 * 1️⃣ If you modify UI features, inform the backend team.
 * 2️⃣ Always test new settings in a separate environment before pushing to production.
 * 3️⃣ Check TradingView's official API docs for compatibility:
 *    🔗 https://www.tradingview.com/rest-api-spec/
 *
 * ========================================================================
 * 🎯 Component Purpose:
 * - Initializes and configures the TradingView widget.
 * - Syncs TradingView settings with Redux state & API data.
 * - Handles watchlist updates and UI customizations.
 * ========================================================================
 */

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './index.css';
import { widget } from '../../charting_library';
import { getNewsAction } from '../../store/news/newsThunk';
import {
  createWatchlistAction,
  getWatchlistAction,
  updateWatchlistAction,
} from '../../store/watchlist/watchlistThunk';

/**
 * Extracts language preference from the URL parameters.
 * @returns {string|null} - Returns language code or null if not found.
 */
function getLanguageFromURL() {
  const regex = new RegExp('[\\?&]lang=([^&#]*)');
  const results = regex.exec(window.location.search);
  return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

// Global toggle for hiding connection status methods
const HIDE_CONNECTION_STATUS_METHOD = true;

/** Console Color Helpers */
const green = (text) => `\x1B[1;4;32m${text}\x1B[0m`;
const red = (text) => `\x1B[1;4;31m${text}\x1B[0m`;
const blue = (text) => `\x1B[1;4;34m${text}\x1B[0m`;
const callColor = (callNumber) => `\x1B[${41 + (callNumber % 6)}m[${callNumber}]\x1B[0m`;

// Keeps track of function call count for logging
let evokeCount = 0;

/**
 * Wraps an object with a Proxy to log method calls with their arguments and return values.
 * @param {Object} obj - The object whose methods need to be logged.
 * @param {string} objName - The name of the object for logging purposes.
 * @returns {Proxy} - A proxied version of the object with logging.
 */
function logMethodCalls(obj, objName) {
  return new Proxy(obj, {
    get(target, propKey, receiver) {
      const property = target[propKey];
      if (
        typeof property === 'function' &&
        !propKey.startsWith('_') &&
        (!HIDE_CONNECTION_STATUS_METHOD || propKey !== 'connectionStatus')
      ) {
        return function (...args) {
          const evokeID = evokeCount++;
          const objPrint = objName === 'Broker' ? blue(`<${objName}>`) : green(`<${objName}>`);
          const idPrint = callColor(evokeID);
          console.log(`${idPrint}${objPrint} Calling method: ${propKey}`);
          console.log(`   Arguments: ${JSON.stringify(args)}`);
          const result = property.apply(this, args);
          if (result instanceof Promise) {
            return result.then((resolvedResult) => {
              console.log(
                `RETURNED: ${red('(async)')} ${idPrint}${objPrint} (${propKey})`,
                resolvedResult,
              );
              return resolvedResult;
            });
          }
          console.log(`RETURNED: ${idPrint}${objPrint} (${propKey})`, result);
          return result;
        };
      }
      return property;
    },
  });
}

/**
 * TradingView Chart Container Component
 */
export const TVChartContainer = (props) => {
  const chartContainerRef = useRef();
  const dispatch = useDispatch();
  const newsState = useSelector((state) => state.newsSlice.news.data);
  const { data: watchlistData, loading } = useSelector((state) => state.watchlist);
  const [currentSymbol, setCurrentSymbol] = useState('');

  /** Fetches news when the symbol changes */
  useEffect(() => {
    dispatch(getNewsAction(props.symbol));
  }, [dispatch, props.symbol]);

  /** Fetches watchlist on component mount */
  useEffect(() => {
    dispatch(getWatchlistAction());
  }, [dispatch]);

  /** Watches for watchlist updates */
  useEffect(() => {
    if (!loading && watchlistData) {
      console.log('Updated Watchlist Data:', watchlistData);
    }
  }, [loading, watchlistData]);

  /** Initializes TradingView Widget */
  useEffect(() => {
    if (!chartContainerRef.current) {
      console.error('❌ Chart container is null. Cannot initialize TradingView.');
      return;
    }

    /** Hides the remove button inside iframe by injecting CSS - watchlist close button hide work-around */
    setTimeout(() => {
      const iframe = document.querySelector('iframe'); // Adjust if multiple iframes
      if (iframe && iframe.contentDocument) {
        const style = document.createElement('style');
        style.innerHTML = `
          span[data-name='remove-button'] {
            display: none !important;
            visibility: hidden !important;
          }
          .removeButton-Pho75f2H {
            display: none !important;
          }
          table.positions .icon-oHlQhto0.editIcon-oHlQhto0,
            table.positions .icon-oHlQhto0[title="Close"] {
              display: none !important;
              visibility: hidden !important;
          }
      `;
        iframe.contentDocument.head.appendChild(style);
      }
    }, 300);

    /** TradingView Widget Configuration */
    const widgetOptions = {
      // position: {
      //   lineColor: '#0000FF', // 🔵 Set position line color to blue
      //   lineStyle: 0, // Solid line (0 = solid, 1 = dotted, 2 = dashed)
      //   lineWidth: 2, // Thickness of the line
      //   quantityTextColor: '#0000FF', // Text showing quantity
      //   quantityBackgroundColor: '#DDEEFF', // Background behind quantity box
      //   bodyTextColor: '#0000FF', // Text shown inside the position box
      // },

      /**
       * Symbol to be displayed on the chart
       * Dynamically set based on props
       */
      symbol: props.symbol,

      /**
       * Datafeed for fetching market data
       * Uses UDF-compatible datafeed with headers from props
       */
      datafeed: new window.Datafeeds.UDFCompatibleDatafeed(props.datafeedUrl, props.headers),

      /**
       * DOM container for rendering the TradingView widget
       */
      container: chartContainerRef.current,

      /**
       * Path to TradingView library scripts
       */
      library_path: '/charting_library/',

      /**
       * Sets language from URL or defaults to English
       */
      locale: getLanguageFromURL() || 'en',

      // 🚫 Features disabled in TradingView UI
      disabled_features: [
        'use_localstorage_for_settings',
        'context_menus',
        'timezone_menu',
        'show_object_tree',
        'symbol_info',
        'order_info',
      ],
      // disabled_features: props.disabled_features, // Use this on prod

      // ✅ Features enabled in TradingView UI
      enabled_features: [
        'header_saveload',
        'study_templates',
        'confirm_overwrite_if_chart_layout_with_name_exists',
        'has_intraday',
        'seconds_resolution',
        'header_screenshot',
        'header_settings',
        'dom_widget',
        'widgetbar_watchlist',
        'display_market_status',
        'show_symbol_logos',
        'show_exchange_logos',
        'show_symbol_logo_in_account_manager',
        'show_symbol_logo_in_legend',
        'chart_hide_close_position_button', // TODO: Add this to api response
      ],
      // enabled_features: props.enabled_features, // Use this on prod

      // 🔄 Supported time intervals for the chart
      supported_resolutions: ['1', '5', '15', '30', '60', '1D', '1W', '1M'],

      // 🌎 Timezone settings
      timezone: 'Asia/Colombo',

      // 📊 Chart storage configuration
      charts_storage_url: props.chartsStorageUrl,
      charts_storage_api_version: props.chartsStorageApiVersion,

      // 🆔 User and client identification
      client_id: props.clientId,
      user_id: props.userId,

      // 📺 Fullscreen and autosize behavior
      fullscreen: props.fullscreen,
      autosize: props.autosize,

      // 📈 Study settings overrides (custom indicators)
      studies_overrides: props.studiesOverrides,

      // 📌 Widget bar settings (enables watchlist, news, details, data window)
      widgetbar: {
        details: true,
        news: true,
        watchlist: true,
        datawindow: true,
        watchlist_settings: {
          default_symbols: [
            'ACL.N0000',
            'AGST.N0000',
            'HAYL.N0000',
            'LIOC.N0000',
            'HNB.N0000',
            'AAF.N0000',
            'AGAL.N0000',
          ],
          // readonly: true, // Uncomment to make the watchlist read-only
        },
      },
      // widgetbar: props.widgetbar, //use this in live

      // 🛠 Debug mode
      debug: true,

      // 📰 RSS News Feed Configuration
      rss_news_feed: {
        default: [
          {
            url: `https://asec.dev.arimac.digital/atrad-svc/api/news/rss?symbol=${currentSymbol}`,
            name: 'Market news',
          },
        ],
      },

      // overrides: {
      //   'positionLine.color': '#aa5733', // Change to your desired hex color
      //   // You can add more style overrides here if needed
      //   'linetoolposition.lineBuyColor': '#00FF00', // Green for Buy
      //   'linetoolposition.lineSellColor': 'blue', // Red for Sell
      // },

      // overrides: {
      //   'linetoolposition.lineBuyColor': '#00FFdd', // Buy line = green
      //   'linetoolposition.lineSellColor': '#FF00dd', // Sell line = red
      //   'linetoolposition.bodyTextPositiveColor': '#00FFdd',
      //   'linetoolposition.bodyTextNegativeColor': '#FF00dd',
      //   'position.line.color': '#00FF00',
      // },
      // settings_overrides: {
      //   'linetooltrendline.linecolor': 'blue',
      // },

      // ⚡ Trading options settings
      trading_options: {
        show_order_lines: false, // Hides order execution lines
        // show_position_lines: false, // Hides position lines
      },

      // 🖊️ Permissions for drawing tools and studies
      drawings_access: props.drawings_access,
      studies_access: props.studies_access,

      // 🎨 Theme settings (light/dark)
      theme: props.theme,

      // 🔌 Broker integration factory function
      broker_factory: function (host) {
        const hostProxy = logMethodCalls(host, 'Trading Host'); // Logs broker method calls
        window.tradingHost = hostProxy; // Stores broker reference globally
        const broker = new window.Brokers.BrokerSample(hostProxy, widgetOptions.datafeed);
        return logMethodCalls(broker, 'Broker'); // Logs method calls inside the broker
      },

      // ⚙️ Settings adapter for TradingView user preferences
      settings_adapter: {
        initialSettings: {
          'trading.chart.proterty': '{ "showPricesWithZeroVolume": 0, "showSpread": 0}',
        },
        setValue: function (key, value) {
          console.log(key, value); // Logs settings changes
        },
      },

      // ⚡ Broker configuration settings
      broker_config: {
        configFlags: {
          supportNativeReversePosition: true, // Enables native reverse position functionality
          supportPlaceOrderPreview: true, // Allows order preview before execution
          supportClosePosition: false, // Disables direct position closing
          supportCloseIndividualPosition: false, // Disables closing individual positions
          supportPLUpdate: true, // Enables real-time profit/loss updates
          supportLevel2Data: true, // Enables Level 2 market data (depth)
          supportOrderBrackets: false, // Disables order bracket functionality
          showQuantityInsteadOfAmount: true, // Displays quantity instead of total amount
          supportEditAmount: true, // Enables editing order amounts
          supportMarketBrackets: true, // Allows market bracket orders
          supportPositionBrackets: true, // Enables position bracket orders
          supportPositions: true, // Enables position tracking
          supportDOM: true, // Enables DOM (depth of market) support
          supportStopOrders: false, // Disables stop order functionality
          supportModifyOrderPreview: true, // Enables order preview before modification
        },

        // 🕒 Supported order durations
        durations: [
          { name: 'DAY', value: 'DAY' }, // Order valid for the day
          { name: 'GTC', value: 'GTC' }, // Good Till Canceled order
        ],
      },
    };

    // 🎯 Initialize the TradingView widget
    const tvWidget = new widget(widgetOptions);

    /**
     * 📌 Executes once the TradingView widget is fully initialized
     * - Ensures that TradingView components are loaded before interacting with them
     * - Sets up watchlists, event listeners, and applies UI customizations
     */
    tvWidget.onChartReady(async () => {
      // Initialize the TradingView watchlist API
      const watchlistApi = await tvWidget.watchList();

      // Fetch the initial watchlist data from the Redux store
      dispatch(getWatchlistAction()).then((response) => {
        console.log('Initial watchlist data fetched');
      });

      // Fetch the watchlists from the API and update the TradingView UI
      dispatch(getWatchlistAction()).then((response) => {
        const watchlists = response.payload;

        // If watchlists exist, iterate through them and add to TradingView watchlist
        if (watchlists && watchlists.length > 0) {
          watchlists.forEach(({ id, listName, symbols }) => {
            watchlistApi.createList(listName, symbols);
          });
        }
      });

      // Apply TradingView overrides to disable specific UI elements
      tvWidget.applyOverrides({
        'tradingProperties.showOrders': false, // Hides order lines on the chart
        'tradingProperties.showReverse': false, // Hides the reverse position button
      });

      /**
       * 🛠 Handle new watchlist addition in TradingView
       * - When a new watchlist is added, fetch and update the Redux store
       */
      watchlistApi.onListAdded().subscribe(null, async (newListId) => {
        console.log('New watchlist added:', newListId);

        if (watchlistData && watchlistData.length > 0) {
          console.log('Updating TradingView watchlist:', watchlistData);

          // Iterate through current watchlists and create them in TradingView
          watchlistData.forEach(({ id, listName, symbols }) => {
            watchlistApi.createList(listName, symbols);
          });

          // Set the first watchlist as active
          const firstListId = watchlistData[0]?.id;
          if (firstListId) {
            watchlistApi.setActiveList(firstListId);
          }
        }

        setTimeout(() => {
          // Fetch all watchlists to get their names
          const allLists = watchlistApi.getAllLists();
          console.log('All Watchlists:', allLists);

          if (!allLists || !allLists[newListId]) {
            console.error('Could not find the title for the new watchlist');
            return;
          }

          const newList = allLists[newListId]; // Retrieve the new watchlist object
          console.log('New Watchlist:', newList);

          // Prepare data for API payload
          const newWatchlistPayload = {
            id: newListId, // ID from TradingView
            listName: newList.title, // Extracted name
            symbols: [], // Initially empty symbols
          };

          // Save the new watchlist via API
          dispatch(createWatchlistAction(newWatchlistPayload)).then((response) => {
            if (response.meta.requestStatus === 'fulfilled') {
              console.log('Successfully created watchlist:', newList.name);
            } else {
              console.error('Failed to create watchlist:', response.error);
            }
          });
        }, 2000); // Delay ensures the new list is fully loaded
      });

      /**
       * 🔄 Handle watchlist content updates
       * - Updates the Redux store when items are added or removed from a watchlist
       */
      watchlistApi.onListChanged().subscribe(null, () => {
        console.log('Watchlist updated.');

        const updatedWatchlistComplete = watchlistApi.getAllLists();
        console.log('Updated watchlist complete:', updatedWatchlistComplete);

        // Ensure it's an object before transforming
        if (!updatedWatchlistComplete || typeof updatedWatchlistComplete !== 'object') {
          console.error('Invalid watchlist object:', updatedWatchlistComplete);
          return;
        }

        // Convert the object to an array of watchlist objects and rename `title` to `listName`
        const watchlistData = Object.entries(updatedWatchlistComplete).map(([id, data]) => ({
          id,
          listName: data.title,
          symbols: data.symbols || [], // Ensure `symbols` is included
        }));

        console.log('watchlistData (transformed array):', watchlistData);
        console.log('Is Array:', Array.isArray(watchlistData));

        if (!Array.isArray(watchlistData)) {
          console.error('Invalid watchlist array after transformation:', watchlistData);
          return;
        }

        // Dispatch Redux action to update the entire watchlist
        dispatch(updateWatchlistAction(watchlistData))
          .then((response) => {
            if (response.meta?.requestStatus === 'fulfilled') {
              console.log('Watchlist updated successfully:', response.payload);
            } else {
              console.error('Failed to update watchlist:', response.error);
            }
          })
          .catch((error) => {
            console.error('Error updating watchlist:', error);
          });
      });

      /**
       * 🔄 Handle active watchlist change
       * - Logs the active watchlist when it is changed
       */
      watchlistApi.onActiveListChanged().subscribe(null, () => {
        console.log('Active watchlist changed');

        // Get the active watchlist ID
        const activeListId = watchlistApi.getActiveListId();
        const currentListItems = watchlistApi.getList(activeListId);

        console.log('Active watchlist items:', currentListItems);
      });

      /**
       * ✏️ Handle watchlist rename event
       * - Logs when a watchlist is renamed
       */
      watchlistApi.onListRenamed().subscribe(null, ({ listId, newName }) => {
        console.log(`Watchlist renamed: ${listId} → New name: ${newName}`);

        // Get the updated watchlist
        const updatedWatchlistComplete = watchlistApi.getAllLists();
        console.log('Updated watchlist complete after rename:', updatedWatchlistComplete);

        if (!updatedWatchlistComplete || typeof updatedWatchlistComplete !== 'object') {
          console.error('Invalid watchlist object after rename:', updatedWatchlistComplete);
          return;
        }

        // Convert object to an array with updated list names
        const watchlistData = Object.entries(updatedWatchlistComplete).map(([id, data]) => ({
          id,
          listName: id === listId ? newName : data.title, // Update the renamed list
          symbols: data.symbols || [],
        }));

        console.log('watchlistData (after rename transformation):', watchlistData);

        if (!Array.isArray(watchlistData)) {
          console.error('Invalid watchlist array after rename transformation:', watchlistData);
          return;
        }

        // Dispatch Redux action to update the watchlist with the new name
        dispatch(updateWatchlistAction(watchlistData))
          .then((response) => {
            if (response.meta?.requestStatus === 'fulfilled') {
              console.log('Watchlist rename updated successfully:', response.payload);
            } else {
              console.error('Failed to update renamed watchlist:', response.error);
            }
          })
          .catch((error) => {
            console.error('Error updating renamed watchlist:', error);
          });
      });
    });

    // Cleanup function to remove the TradingView widget when the component unmounts
    return () => {
      tvWidget.remove(); // Ensures the TradingView instance is properly destroyed
    };
  }, [
    props.symbol, // Tracks symbol changes to update the TradingView widget
    currentSymbol, // Ensures the component updates when the symbol changes
    props.datafeedUrl, // Updates the widget when the datafeed URL changes
    props.headers, // Ensures API headers are updated dynamically
    props.disabled_features, // Tracks changes to disabled TradingView features
    props.chartsStorageUrl, // Updates the widget if the storage URL for charts changes
    props.chartsStorageApiVersion, // Ensures the API version updates correctly
    props.clientId, // Reacts to changes in the client ID for authentication
    props.userId, // Reacts to changes in the user ID
    props.fullscreen, // Updates when fullscreen mode toggles
    props.autosize, // Ensures auto-size updates for responsiveness
    props.studiesOverrides, // Watches for any changes to studies/custom indicators
    props.widgetbar, // Tracks updates to widget settings
    props.drawings_access, // Updates TradingView drawing access permissions
    props.studies_access, // Updates study access settings
    props.theme, // Ensures theme changes are reflected dynamically
    dispatch, // Triggers re-fetching of Redux data when needed
    watchlistData, // Updates TradingView watchlists when Redux data changes
    props.enabled_features, // Tracks updates to enabled TradingView features
  ]);

  // Render the TradingView chart container
  return <div ref={chartContainerRef} className={'TVChartContainer'} />;
};
