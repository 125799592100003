import { configureStore } from '@reduxjs/toolkit';
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import persistSessionStore from 'redux-persist/lib/storage/session';
import commonSlice from './common/commonSlice';
import registerSlice from './register/registerSlice';
import flowSlice from './flowSlice';
import loginSlice from './login/loginSlice';
import passwordSlice from './password/passwordSlice';
import companyDetailsSlice from './companyDetails/companyDetailsSlice';
import subscriptionSlice from './subscription/subscriptionSlice';
import faqSlice from './faq/faqSlice';
import profileSlice from './profile/profileSlice';
import termsAndCondtionSlice from './terms-and-condition/termsAndCondtionSlice';
import privacyPolicySlice from './privacy-policy/privacyPolicySlice';
import tvChartSlice from './tv-chart-config/tv-chart-configSlice';
import newsSlice from './news/newsSlice';
import paymentSlice from './payments/paymentSlice';
import watchlistSlice from './watchlist/watchlistSlice';
import themeReducer from './common/themeSlice';

const userProfilePersistsConfig = {
  key: 'userProfile',
  storage: persistSessionStore,
  whitelist: ['userProfileData'],
};

const subscriptionPersistsConfig = {
  key: 'subscription',
  storage: persistSessionStore,
  whitelist: [
    'selectedSubscriptionId',
    'selectedSubscriptionType',
    'subscriptionChange',
    'subscriptionPlansData',
  ],
};

const store = configureStore({
  reducer: {
    states: commonSlice,
    registerSlice: registerSlice,
    flowSlice: flowSlice,
    loginSlice: loginSlice,
    passwordSlice: passwordSlice,
    companyDetailsSlice: companyDetailsSlice,
    faqSlice: faqSlice,
    profileSlice: profileSlice,
    termsAndCondtionSlice: termsAndCondtionSlice,
    privacyPolicySlice: privacyPolicySlice,
    subscriptionSlice: persistReducer(subscriptionPersistsConfig, subscriptionSlice),
    profileSlice: persistReducer(userProfilePersistsConfig, profileSlice),
    tvChartSlice: tvChartSlice,
    theme: themeReducer,
    newsSlice: newsSlice,
    paymentSlice: paymentSlice,
    watchlist: watchlistSlice,
  },
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

const asecPersistStore = persistStore(store);

export { store, asecPersistStore };
