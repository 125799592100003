import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toggleDarkMode } from '../../store/common/themeSlice';
import { useDispatch, useSelector } from 'react-redux';

const DropdownMenu = ({ onLogout, lang }) => {
  const [dropdown, setDropdown] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isDarkMode = useSelector((state) => state?.theme?.isDarkMode);

  return (
    <>
      <div
        className='dashboard-layout-profile-arrow icon-arrow-down-2'
        onClick={() => setDropdown(true)}
      />
      {dropdown && (
        <>
          <div className='dashboard-layout-dropdown'>
            <div className='dashboard-layout-dropdown-list'>
              {/* <input
                type='checkbox'
                class='checkbox'
                id='checkbox'
                onChange={() => {
                  // setIsDarkMode(!isDarkMode);
                }}
              /> */}
              {/* <label for='checkbox' class='checkbox-label'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  class='icon icon-tabler icon-tabler-moon-stars'
                  width='44'
                  height='44'
                  viewBox='0 0 24 24'
                  stroke-width='1.5'
                  stroke='#ffbf00'
                  fill='none'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                >
                  <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                  <path d='M12 3c.132 0 .263 0 .393 0a7.5 7.5 0 0 0 7.92 12.446a9 9 0 1 1 -8.313 -12.454z' />
                  <path d='M17 4a2 2 0 0 0 2 2a2 2 0 0 0 -2 2a2 2 0 0 0 -2 -2a2 2 0 0 0 2 -2' />
                  <path d='M19 11h2m-1 -1v2' />
                </svg>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  class='icon icon-tabler icon-tabler-brightness-down'
                  width='44'
                  height='44'
                  viewBox='0 0 24 24'
                  stroke-width='1.5'
                  stroke='#ffbf00'
                  fill='none'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                >
                  <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                  <path d='M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0' />
                  <path d='M12 5l0 .01' />
                  <path d='M17 7l0 .01' />
                  <path d='M19 12l0 .01' />
                  <path d='M17 17l0 .01' />
                  <path d='M12 19l0 .01' />
                  <path d='M7 17l0 .01' />
                  <path d='M5 12l0 .01' />
                  <path d='M7 7l0 .01' />
                </svg>
                <span class='ball'></span>
              </label> */}

              <div
                className='dashboard-layout-dropdown-item typo-body-v3'
                onClick={() => {
                  navigate('/pricing');
                  setDropdown(false);
                }}
              >
                Pricing
              </div>

              <div
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                className='dashboard-layout-dropdown-item typo-body-v3'
                onClick={() => {
                  dispatch(toggleDarkMode());
                  // setDropdown(false);
                }}
              >
                {isDarkMode ? 'Light mode' : 'Dark mode'}
                <label className='toggle-switch'>
                  <input
                    type='checkbox'
                    checked={isDarkMode}
                    onChange={() => {
                      dispatch(toggleDarkMode());
                      // setDropdown(false);
                    }}
                  />
                  <span className='slider'></span>
                </label>
              </div>

              <div
                className='dashboard-layout-dropdown-item typo-body-v3 logout'
                onClick={() => {
                  onLogout();
                  setDropdown(false);
                }}
              >
                {lang.layout.dropdown.logout}
              </div>
            </div>
          </div>
          <div className='dashboard-layout-dropdown-backdrop' onClick={() => setDropdown(false)} />
        </>
      )}
    </>
  );
};

export default DropdownMenu;
