import { createSlice } from '@reduxjs/toolkit';
import { Flow, Step } from '../constants/enums';

const initialState = {
  flowData: {
    current: {
      flow: Flow.ONBOARDING,
      step: Step.SIGNUP,
      isSubmitted: false,
    },
    next: {
      flow: '',
      step: '',
    },
    previous: {
      flow: '',
      step: '',
    },
    isCurrentFormSubmitted: false,
  },
};

const flowSlice = createSlice({
  name: 'flowSlice',
  initialState: initialState,
  reducers: {
    setPreviousFlow: (state, action) => {
      state.flowData.previous.flow = action.payload.flow;
      state.flowData.previous.step = action.payload.step;
    },
    setCurrentFlow: (state, action) => {
      state.flowData.current.flow = action.payload.flow;
      state.flowData.current.step = action.payload.step;
      state.flowData.current.isFirstTimeVisit = action.payload.isFirstTimeVisit;
    },
    setNextFlow: (state, action) => {
      state.flowData.next.flow = action.payload.flow;
      state.flowData.next.step = action.payload.step;
    },
    setCurrentFormSubmitStatus(state, action) {
      state.flowData.isCurrentFormSubmitted = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { setCurrentFlow, setNextFlow, setPreviousFlow, setCurrentFormSubmitStatus } =
  flowSlice.actions;
export default flowSlice.reducer;
