import { createSlice } from '@reduxjs/toolkit';
import {
  cdsAccountValidationAction,
  validateNICAction,
  validateOTPForCDSAction,
  verifyEmailAction,
  verifyEmailOTPAction,
  verifyMobileAction,
  verifyMobileOTPAction,
  createUserAction,
  verifyUsernameAvailabilityAction,
} from './registerThunks';

const initialState = {
  user: {
    userId: '',
    isCDSAccountExists: false,
    nicNumber: '',
    cdsAccountNumber: '',
    email: '',
    mobile: '',
    title: 1,
    initials: '',
    nameDonatedByInitials: '',
    surName: '',
    userName: '',
    password: '',
    isTermsAgreed: false,
    isPrivacyPolicyAgreed: false,
  },
  // SIGNUP
  validateNIC: {
    loading: false,
    error: null,
  },
  // CDS_VERIFY
  cdsAccountValidation: {
    loading: false,
    otpDuration: 0,
    error: null,
  },
  // CDS_VERIFY_OTP
  cdsOTPValidation: {
    loading: false,
    error: null,
  },
  // EMAIL_VERIFY
  emailValidation: {
    loading: false,
    duration: 0,
    message: '',
    error: null,
  },
  // EMAIL_VERIFY_OTP
  emailOTPValidation: {
    loading: false,
    message: '',
    error: null,
  },
  // MOBILE_VERIFY
  mobileValidation: {
    loading: false,
    duration: 0,
    message: '',
    error: null,
  },
  // MOBILE_VERIFY_OTP
  mobileOTPValidation: {
    loading: false,
    message: '',
    error: null,
  },
  // USERNAME
  usernameAvailabilityValidation: {
    loading: false,
    data: null,
    error: null,
  },
  // PASSWORD
  createUser: {
    loading: false,
    data: null,
    error: null,
  },
};

const registerSlice = createSlice({
  name: 'registerSlice',
  initialState: initialState,
  reducers: {
    setRegistrationInput: (state = initialState, action) => {
      state.user = {
        ...state.user,
        [action.payload.key]: action.payload.value,
      };
    },
    resetRegisterSlice: () => initialState,
    resetEmailValidation: (state) => {
      state.emailValidation = initialState.emailValidation;
      state.user.email = initialState.user.email;
    },
    resetCDSValidation: (state) => {
      state.cdsAccountValidation = initialState.cdsAccountValidation;
    },
    resetMobileValidation: (state) => {
      state.mobileValidation.message = initialState.mobileValidation.message;
    },
  },
  extraReducers: (builder) => {
    builder
      // validateNICAction - SIGNUP
      .addCase(validateNICAction.pending, (state = initialState) => {
        state.validateNIC.loading = true;
        state.validateNIC.error = null;
      })
      .addCase(validateNICAction.fulfilled, (state = initialState, action) => {
        state.validateNIC.loading = false;
        state.user.isCDSAccountExists = action.payload['cdsExists'];
        state.user.userId = action.payload['userId'];
      })
      .addCase(validateNICAction.rejected, (state = initialState, action) => {
        state.validateNIC.loading = false;
        state.validateNIC.error = action.payload;
      })
      // cdsAccountValidationAction - CDS_VERIFY
      .addCase(cdsAccountValidationAction.pending, (state = initialState) => {
        state.cdsAccountValidation.loading = true;
        state.cdsAccountValidation.error = null;
      })
      .addCase(cdsAccountValidationAction.fulfilled, (state = initialState, action) => {
        state.cdsAccountValidation.loading = false;
        state.cdsAccountValidation.otpDuration = action.payload['otpDuration'];
        state.user.email = action.payload['email'];
        state.user.mobile = action.payload['mobile'];
      })
      .addCase(cdsAccountValidationAction.rejected, (state = initialState, action) => {
        state.cdsAccountValidation.loading = false;
        state.cdsAccountValidation.error = action.payload;
      })
      // validateOTPForCDSAction - CDS_VERIFY_OTP
      .addCase(validateOTPForCDSAction.pending, (state = initialState) => {
        state.cdsOTPValidation.loading = true;
        state.cdsOTPValidation.error = null;
      })
      .addCase(validateOTPForCDSAction.fulfilled, (state = initialState, action) => {
        state.cdsOTPValidation.loading = false;
        state.user.title = action.payload['title'] || 1;
        state.user.initials = action.payload['initials'];
        state.user.nameDonatedByInitials = action.payload['nameWithInitials'];
        state.user.surName = action.payload['lastname'];
      })
      .addCase(validateOTPForCDSAction.rejected, (state = initialState, action) => {
        state.cdsOTPValidation.loading = false;
        state.cdsOTPValidation.error = action.payload;
      })
      // verifyEmailAction - EMAIL_VERIFY
      .addCase(verifyEmailAction.pending, (state = initialState) => {
        state.emailValidation.loading = true;
        state.emailValidation.error = null;
      })
      .addCase(verifyEmailAction.fulfilled, (state = initialState, action) => {
        state.emailValidation.loading = false;
        state.emailValidation.duration = action.payload['duration'];
        state.emailValidation.message = action.payload['message'];
      })
      .addCase(verifyEmailAction.rejected, (state = initialState, action) => {
        state.emailValidation.loading = false;
        state.emailValidation.error = action.payload;
      })
      // verifyEmailOTPAction - EMAIL_VERIFY_OTP
      .addCase(verifyEmailOTPAction.pending, (state = initialState) => {
        state.emailOTPValidation.loading = true;
        state.emailOTPValidation.error = null;
      })
      .addCase(verifyEmailOTPAction.fulfilled, (state = initialState, action) => {
        state.emailOTPValidation.loading = false;
        state.emailOTPValidation.message = action.payload['message'];
      })
      .addCase(verifyEmailOTPAction.rejected, (state = initialState, action) => {
        state.emailOTPValidation.loading = false;
        state.emailOTPValidation.error = action.payload;
      })
      // verifyMobileAction - MOBILE_VERIFY
      .addCase(verifyMobileAction.pending, (state = initialState) => {
        state.mobileValidation.loading = true;
        state.mobileValidation.error = null;
      })
      .addCase(verifyMobileAction.fulfilled, (state = initialState, action) => {
        state.mobileValidation.loading = false;
        state.mobileValidation.duration = action.payload['duration'];
        state.mobileValidation.message = action.payload['message'];
      })
      .addCase(verifyMobileAction.rejected, (state = initialState, action) => {
        state.mobileValidation.loading = false;
        state.mobileValidation.error = action.payload;
      })
      // verifyMobileOTPAction - MOBILE_VERIFY_OTP
      .addCase(verifyMobileOTPAction.pending, (state = initialState) => {
        state.mobileOTPValidation.loading = true;
        state.mobileOTPValidation.error = null;
      })
      .addCase(verifyMobileOTPAction.fulfilled, (state = initialState, action) => {
        state.mobileOTPValidation.loading = false;
        state.mobileOTPValidation.message = action.payload['message'];
      })
      .addCase(verifyMobileOTPAction.rejected, (state = initialState, action) => {
        state.mobileOTPValidation.loading = false;
        state.mobileOTPValidation.error = action.payload;
      })
      //  verifyUsernameAvailabilityAction - USERNAME
      .addCase(verifyUsernameAvailabilityAction.pending, (state = initialState) => {
        state.usernameAvailabilityValidation.loading = true;
        state.usernameAvailabilityValidation.error = null;
      })
      .addCase(verifyUsernameAvailabilityAction.fulfilled, (state = initialState, action) => {
        state.usernameAvailabilityValidation.loading = false;
        state.usernameAvailabilityValidation.data = action.payload;
      })
      .addCase(verifyUsernameAvailabilityAction.rejected, (state = initialState, action) => {
        state.usernameAvailabilityValidation.loading = false;
        state.usernameAvailabilityValidation.error = action.payload;
        state.usernameAvailabilityValidation.data = null;
      })
      // createUserAction - PASSWORD
      .addCase(createUserAction.pending, (state = initialState) => {
        state.createUser.loading = true;
        state.createUser.error = null;
      })
      .addCase(createUserAction.fulfilled, (state = initialState, action) => {
        state.createUser.loading = false;
        state.createUser.data = action.payload;
      })
      .addCase(createUserAction.rejected, (state = initialState, action) => {
        state.createUser.loading = false;
        state.createUser.error = action.payload;
      });
  },
});

export const {
  setRegistrationInput,
  resetRegisterSlice,
  resetEmailValidation,
  resetCDSValidation,
  resetMobileValidation,
} = registerSlice.actions;
export default registerSlice.reducer;
