// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TVChartContainer {
  height: calc(100vh - 80px);
  /* background-color: brown; */
  /* margin-bottom: 10%; */
}
`, "",{"version":3,"sources":["webpack://./src/components/TVChartContainer/index.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,6BAA6B;EAC7B,wBAAwB;AAC1B","sourcesContent":[".TVChartContainer {\n  height: calc(100vh - 80px);\n  /* background-color: brown; */\n  /* margin-bottom: 10%; */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
