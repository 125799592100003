import { getSession } from './helpers';
import { postLogoutUser } from '../api/lib/auth.api';

// utils to validate text
export const ValidateText = {
  // is any number
  isAnyNumber: (input) => /(?=.*\d)/.test(input),
  // is any lowercase character
  isAnyLowerCase: (input) => /(?=.*[a-z])/.test(input),
  // is any uppercase character
  isAnyUpperCase: (input) => /(?=.*[A-Z])/.test(input),
  // is any symbol
  isAnySymbol: (input) => /(?=.*\W)/.test(input),
  // is minimum length
  isMinLength: (input, length = 8) => input.length >= length,
  // is valid nic
  isNIC: (input) => /^([0-9]{9}[xXvV]|[0-9]{12})$/m.test(input),
  // is valid passport
  isPassport: (input) => /^(?!^0+$)[a-zA-Z0-9]{6,9}$/m.test(input),
  // is valid email address
  isEmail: (input) => /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/m.test(input),
  // is valid credit card
  isCreditCard: (input) => input.length === 19,
  // is valid expiry date
  isExpiryDate: (input) => /^(0[1-9]|1[0-2]) \/ ?([0-9]{4}|[0-9]{2})$/.test(input),
  // is valid ccv
  isCCV: (input) => /^[0-9]{3,4}$/.test(input),
  // is valid create-new-password
  isPassword: [
    // max 8 characters
    (x) => x.length >= 8,
    // at least uppercase character
    /(?=.*[A-Z])/,
    // at least special character
    /(?=.*\W)/,
    // at least lowercase character
    /(?=.*[a-z])/,
    // at least one number
    /(?=.*\d)/,
  ],
  isUsername: [
    // // Username supports alphanumeric characters only
    /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]+$/,
    // min 8 characters and max 25 characters
    (u) => u.length >= 8 && u.length <= 25,
  ],
  isMobile: (input) => /^(?:\947|0?7)\d{8}$/.test(input),
};

const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

// utils to format text
export const FormatText = {
  toDate: (input) => {
    // get date by type
    const date = typeof input === 'number' ? new Date(input) : input;
    // get month by index
    const month = months[date.getMonth()];
    // get day
    const day = date.getDate();
    // get year
    const year = date.getFullYear();
    // return string
    return `${month} ${day}, ${year}`;
  },
  toPrice: (input) => {
    let amount = 0;
    if (input) {
      amount = input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    return `${amount} LKR`;
  },
  toDiscount: (input) => {
    let discount = 0;
    discount = input;

    return `${discount} % OFF`;
  },
  toString: (input, date, amount) => {
    // return if no string
    if (typeof input !== 'string') {
      return '';
    }
    // replace all date markers
    while (input.includes('{DATE}')) {
      input = input.replace('{DATE}', date);
    }
    // replace all amount markers
    while (input.includes('{AMOUNT}')) {
      input = input.replace('{AMOUNT}', amount);
    }
    // return input
    return input;
  },
};

export const stringTemplateParser = (expression, valueObj) => {
  const templateMatcher = /{{\s?([^{}\s]*)\s?}}/g;
  return expression.replace(templateMatcher, (substring, value, index) => {
    value = valueObj[value];
    return value;
  });
};

/**
 * The formatMobileNumber function takes a mobile number as input and returns it in a formatted string
 * with the country code and spaces.
 * @param number - The `number` parameter is a string representing a mobile phone number.
 * @returns a formatted mobile number in the format '94 XX XXX XXXX', where 'XX' represents the
 * country code and 'XXX XXXX' represents the remaining digits of the mobile number.
 */
export const formatMobileNumber = (number) => {
  return '94 ' + number.slice(1, 3) + ' ' + number.slice(3, 6) + ' ' + number.slice(6);
};

/**
 * The JWTDecoder function decodes a JSON Web Token (JWT) by splitting it into three parts and decoding
 * the second part using the base64 algorithm.
 * @param token - The `token` parameter is a JSON Web Token (JWT) that is used for authentication and
 * authorization purposes. It is a string that consists of three parts separated by dots: the header,
 * the payload, and the signature. The `JWTDecoder` function takes this token as input and decodes the
 * @returns the decoded JSON object from the JWT token.
 */
export const JWTDecoder = (token) => {
  return JSON.parse(atob(token.split('.')[1]));
};

/**
 * The function `getIsUserAuthenticated` checks if the user is authenticated based on the presence of
 * an auth token and specific roles in the decoded token.
 * @returns The function `getIsUserAuthenticated` returns a boolean value. It returns `true` if the
 * user is authenticated and `false` if the user is not authenticated.
 */
export const getIsUserAuthenticated = () => {
  const authToken = getSession('auth_token');

  if (authToken) {
    const decodedToken = JWTDecoder(authToken);

    if (
      decodedToken.resource_access &&
      decodedToken.resource_access.asec_user_client_service &&
      decodedToken.resource_access.asec_user_client_service.roles &&
      decodedToken.resource_access.asec_user_client_service.roles.length > 0 &&
      decodedToken.resource_access.asec_user_client_service.roles.includes('asec_guest_role')
    ) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
};

// logout function
export const logoutAndRedirect = async () => {
  const refreshToken = sessionStorage.getItem('refresh_token');
  try {
    if (refreshToken) {
      const response = await postLogoutUser();
      if (response.status === 200) {
        sessionStorage.clear();
        window.location.href = '/login';
      } else {
        console.error('Logout failed:', response);
      }
    } else {
      sessionStorage.clear();
      window.location.href = '/login';
    }
  } catch (error) {
    console.error('Error during logout:', error);
  }
};

export const logout = async () => {
  try {
    const response = await postLogoutUser();
    if (response.status === 200) {
      sessionStorage.clear();
    } else {
      console.error('Logout failed:', response);
    }
  } catch (error) {
    console.error('Error during logout:', error);
  }
};

export const getCardType = (cardNumber) => {
  if (cardNumber.startsWith('4')) {
    return 'visa';
  }

  if (
    cardNumber.startsWith('51') ||
    cardNumber.startsWith('52') ||
    cardNumber.startsWith('53') ||
    cardNumber.startsWith('54') ||
    cardNumber.startsWith('55')
  ) {
    return 'mastercard';
  }

  if (cardNumber.startsWith('34') || cardNumber.startsWith('37')) {
    return 'americanExpress';
  }

  return `mastercard`;
};

/**
 * The `formatPrice` function takes a price as input, formats it with commas for thousands separator
 * and pads the decimal part with zeros if necessary before returning the formatted price as a string.
 * @param price - Price is the numerical value that you want to format into a currency format with
 * commas for thousands separator and two decimal places.
 * @returns The `formatPrice` function returns a formatted price string with commas for thousands
 * separator and two decimal places.
 */
export const formatPrice = (price) => {
  if (price) {
    const parts = price.toString().split('.');
    const formattedInteger = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const decimalPart = parts[1] ? parts[1].padEnd(2, '0') : '00';
    return formattedInteger + '.' + decimalPart;
  }
  return ``;
};

export const formatPnLPrice = (price) => {
  if (price) {
    const fixedPrice = price.toFixed(2); // Limit to 2 decimal places
    const parts = fixedPrice.split('.');
    const formattedInteger = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const decimalPart = parts[1] ? parts[1] : '00';
    return formattedInteger + '.' + decimalPart;
  }
  return ``;
};

/**
 * The `formatPercentage` function takes a percentage value as input,
 * rounds it to four decimal places, and returns it as a string.
 * If the input is 0, it returns "0.0000".
 * @param percentage - The numerical value you want to format to four decimal places.
 * @returns The `formatPercentage` function returns a formatted percentage string
 * with four decimal places, or "0.0000" if the input is 0.
 */
export const formatPercentage = (percentage) => {
  if (percentage === 0) {
    return '0.00';
  }

  if (percentage) {
    // Round the value to four decimal places
    const roundedValue = percentage.toFixed(2);
    return roundedValue;
  }

  return '';
};

export const formatPercentageWithComas = (percentage) => {
  try {
    if (percentage === 0) {
      return '0.00';
    }

    if (percentage) {
      // Format the number with comma separators and two decimal places
      return percentage.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    return '';
  } catch (e) {
    return '';
  }
};

export const formatQuantity = (quantity) => {
  if (quantity) {
    const parts = quantity.toString().split('.');
    const formattedInteger = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return formattedInteger;
  }
  return ``;
};
