import { createAsyncThunk } from '@reduxjs/toolkit';
// import { initPayment } from '../../api/lib/payment.api';
import {
  cancelSubscriptionAPI,
  paymentInitAPI,
  getPaymentStatusAPI,
  retryPaymentAPI,
  subscribeToTrialWithoutCardAPI,
  saveUserSubscriptionAPI,
} from '../../api/lib/paymentinit.api';
// import axios from 'axios';

export const initPaymentAction = createAsyncThunk(
  'paymentSlice/initPaymentAction',
  async (paymentData, { rejectWithValue }) => {
    try {
      const response = await paymentInitAPI(paymentData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  },
);

export const cancelSubscriptionAction = createAsyncThunk(
  'paymentSlice/cancelSubscriptionAction',
  async (userId, { rejectWithValue }) => {
    try {
      const response = await cancelSubscriptionAPI();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  },
);

export const getPaymentStatusAction = createAsyncThunk(
  'paymentSlice/getPaymentStatusAction',
  async (requestId, { rejectWithValue }) => {
    try {
      const response = await getPaymentStatusAPI(requestId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  },
);

export const retryPaymentAction = createAsyncThunk(
  'paymentSlice/retryPaymentAction',
  async (paymentData, { rejectWithValue }) => {
    try {
      const response = await retryPaymentAPI(paymentData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  },
);

export const subscribeToTrialWithoutCardAction = createAsyncThunk(
  'paymentSlice/subscribeToTrialWithoutCard',
  async (subscriptionData, { rejectWithValue }) => {
    try {
      const response = await subscribeToTrialWithoutCardAPI(subscriptionData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  },
);

export const saveUserSubscriptionAction = createAsyncThunk(
  'paymentSlice/saveUserSubscriptionAction',
  async (subscriptionData, { rejectWithValue }) => {
    try {
      const response = await saveUserSubscriptionAPI(subscriptionData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  },
);
