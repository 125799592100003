import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  createWatchlist,
  getWatchlist,
  updateWatchlist,
  deleteWatchlist,
} from '../../api/lib/watchlist.api';

// Fetch existing watchlist from API
export const getWatchlistAction = createAsyncThunk(
  'watchlist/getWatchlist',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getWatchlist();
      console.log('Watchlist response: ' + JSON.stringify(response));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);

// Create a new watchlist
export const createWatchlistAction = createAsyncThunk(
  'watchlist/createWatchlist',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await createWatchlist(payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);

export const updateWatchlistAction = createAsyncThunk(
  'watchlist/updateWatchlist',
  async (payload, { rejectWithValue }) => {
    console.log('updateWatchlistAction triggered with:', payload);
    try {
      const response = await updateWatchlist(payload);
      console.log('API Response:', response.data);
      return response.data;
    } catch (error) {
      console.error('API Error:', error.response?.data || error.message);
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);

export const deleteWatchlistAction = createAsyncThunk(
  'watchlist/deleteWatchlist',
  async (listId, { rejectWithValue }) => {
    console.log('deleteWatchlistAction triggered for listId:', listId);
    try {
      const response = await deleteWatchlist(listId);
      console.log('API Response:', response.data);
      return { listId, success: true }; // Return the deleted listId
    } catch (error) {
      console.error('API Error:', error.response?.data || error.message);
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);
