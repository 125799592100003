/* eslint-disable no-restricted-globals */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './index.css';
import { widget } from '../../charting_library';
import { getNewsAction } from '../../store/news/newsThunk';
import {
  createWatchlistAction,
  getWatchlistAction,
  updateWatchlistAction,
  deleteWatchlistAction,
} from '../../store/watchlist/watchlistThunk';

function getLanguageFromURL() {
  const regex = new RegExp('[\\?&]lang=([^&#]*)');
  const results = regex.exec(window.location.search);
  return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

const HIDE_CONNECTION_STATUS_METHOD = true;

function green(text) {
  return `\x1B[1;4;32m${text}\x1B[0m`;
}

function red(text) {
  return `\x1B[1;4;31m${text}\x1B[0m`;
}

function blue(text) {
  return `\x1B[1;4;34m${text}\x1B[0m`;
}

function callColor(callNumber) {
  const i = 41 + (callNumber % 6);
  return `\x1B[${i}m[${callNumber}]\x1B[0m`;
}

let evokeCount = 0;

function logMethodCalls(obj, objName) {
  return new Proxy(obj, {
    get(target, propKey, receiver) {
      const property = target[propKey];
      if (
        typeof property === 'function' &&
        !propKey.startsWith('_') &&
        (!HIDE_CONNECTION_STATUS_METHOD || propKey !== 'connectionStatus')
      ) {
        return function (...args) {
          const evokeID = evokeCount++;
          const objPrint = objName === 'Broker' ? blue(`<${objName}>`) : green(`<${objName}>`);
          const idPrint = callColor(evokeID);
          console.log(`${idPrint}${objPrint} Calling method: ${propKey}`);
          console.log(`   Arguments: ${JSON.stringify(args)}`);
          const result = property.apply(this, args);
          if (result instanceof Promise) {
            return result.then((resolvedResult) => {
              console.log(
                `RETURNED: ${red('(async)')} ${idPrint}${objPrint} (${propKey})`,
                resolvedResult,
              );
              return resolvedResult;
            });
          }
          console.log(`RETURNED: ${idPrint}${objPrint} (${propKey})`, result);
          return result;
        };
      }
      return property;
    },
  });
}

export const TVChartContainer = (props) => {
  const chartContainerRef = useRef();
  const newsState = useSelector((state) => state.newsSlice.news.data);
  const dispatch = useDispatch();

  const { data: watchlistData, loading, error } = useSelector((state) => state.watchlist);

  useEffect(() => {
    if (!loading && watchlistData?.length > 0) {
      console.log('Watchlist data:', watchlistData);
    }
  }, [loading, watchlistData]);

  const [currentSymbol, setCurrentSymbol] = useState('');

  useEffect(() => {
    console.log('Dispatching getNewsAction');
    dispatch(getNewsAction(props.symbol));
    console.log('Dispatching getNewsAction', newsState);
  }, [dispatch, props.symbol]);

  useEffect(() => {
    dispatch(getWatchlistAction());
  }, [dispatch]);

  // UseEffect to log and update when watchlistData changes
  useEffect(() => {
    if (!loading && watchlistData) {
      console.log('Updated Watchlist Data:', watchlistData);
    }
  }, [loading, watchlistData]);

  useEffect(() => {
    const widgetOptions = {
      symbol: props.symbol,
      datafeed: new window.Datafeeds.UDFCompatibleDatafeed(props.datafeedUrl, props.headers),
      container: chartContainerRef.current,
      library_path: '/charting_library/',
      locale: getLanguageFromURL() || 'en',
      disabled_features: props.disabled_features,
      enabled_features: props.enabled_features,
      supported_resolutions: ['1', '5', '15', '30', '60', '1D', '1W', '1M'],
      timezone: 'Asia/Colombo',
      charts_storage_url: props.chartsStorageUrl,
      charts_storage_api_version: props.chartsStorageApiVersion,
      client_id: props.clientId,
      user_id: props.userId,
      fullscreen: props.fullscreen,
      autosize: props.autosize,
      studies_overrides: props.studiesOverrides,
      widgetbar: props.widgetbar,
      debug: true,
      rss_news_feed: {
        default: [
          {
            url: `https://asec.dev.arimac.digital/atrad-svc/api/news/rss?symbol=${currentSymbol}`,
            name: 'Market news',
          },
        ],
      },
      drawings_access: props.drawings_access,
      studies_access: props.studies_access,
      theme: props.theme,
      // broker_factory: function (host) {
      //   return new window.Brokers.BrokerSample(host, widgetOptions.datafeed);
      // },

      broker_factory: function (host) {
        const hostProxy = logMethodCalls(host, 'Trading Host');
        window.tradingHost = hostProxy;
        const broker = new window.Brokers.BrokerSample(hostProxy, widgetOptions.datafeed);
        return logMethodCalls(broker, 'Broker');
      },

      settings_adapter: {
        initialSettings: {
          'trading.chart.proterty': '{ "showPricesWithZeroVolume": 0, "showSpread": 0}',
        },
        setValue: function (key, value) {
          console.log(key, value);
        },
      },
      broker_config: {
        configFlags: {
          supportNativeReversePosition: true,
          supportPlaceOrderPreview: true,
          supportClosePosition: true,
          supportPLUpdate: true,
          supportLevel2Data: true,
          supportOrderBrackets: false,
          showQuantityInsteadOfAmount: true,
          supportEditAmount: true,
          supportMarketBrackets: true,
          supportPositionBrackets: true,
          supportPositions: true,
          supportDOM: true,
        },
        durations: [
          { name: 'DAY', value: 'DAY' },
          { name: 'GTC', value: 'GTC' },
          // { name: 'GTD', value: 'GTD' }, advised by @Shamindri Perera to not display this option as implementation has not been done.
        ],
      },
    };

    const tvWidget = new widget(widgetOptions);

    tvWidget.onChartReady(async () => {
      const watchlistApi = await tvWidget.watchList();

      dispatch(getWatchlistAction()).then((response) => {
        console.log('res res', response.payload);
      }); // Load initial watchlists

      console.log('watchlistData', watchlistData);

      dispatch(getWatchlistAction()).then((response) => {
        const watchlists = response.payload;
        console.log('Loaded Watchlists from API:', watchlists);

        if (watchlists && watchlists.length > 0) {
          watchlists.forEach(({ id, listName, symbols }) => {
            watchlistApi.createList(listName, symbols);
          });

          // Set active list
          // const firstListId = watchlists[0]?.id;
          // if (firstListId) {
          //   watchlistApi.setActiveList(firstListId);
          //   console.log('Set active watchlist:', firstListId);
          // }
        }
      });

      // Handle new list added
      watchlistApi.onListAdded().subscribe(null, async (newListId) => {
        console.log('New list added:', newListId);

        if (watchlistData && watchlistData.length > 0) {
          console.log('Setting watchlist in TradingView:', watchlistData);

          watchlistData.forEach(({ id, listName, symbols }) => {
            // Create the watchlist in TradingView
            watchlistApi.createList(listName, symbols);
          });

          // Set the first watchlist as active
          const firstListId = watchlistData[0]?.id;
          if (firstListId) {
            watchlistApi.setActiveList(firstListId);
          }
        }

        setTimeout(() => {
          // Fetch all lists to find the title
          const allLists = watchlistApi.getAllLists();
          console.log('All Watchlists:', allLists);

          if (!allLists || !allLists[newListId]) {
            console.error('Could not find the title for new watchlist');
            return;
          }

          const newList = allLists[newListId]; // Get the watchlist object

          console.log('New Watchlist:', newList);
          // Prepare payload with extracted title and ID
          const newWatchlistPayload = {
            id: newListId, // ID from TradingView
            listName: newList.title, // Extracted name
            symbols: [], // Initially empty symbols
          };

          // Save the new watchlist via API
          dispatch(createWatchlistAction(newWatchlistPayload)).then((response) => {
            if (response.meta.requestStatus === 'fulfilled') {
              console.log('Successfully created watchlist with name:', newList.name);
            } else {
              console.error('Failed to create watchlist:', response.error);
            }
          });
        }, 2000);
      });

      // Handle list content updates
      watchlistApi.onListChanged().subscribe(null, (listId) => {
        console.log('List changed:', listId);

        const updatedSymbols = watchlistApi.getList(listId);
        console.log('Updated symbols:', updatedSymbols);

        // Ensure valid data before dispatching
        if (!updatedSymbols || !Array.isArray(updatedSymbols)) {
          console.error('Invalid symbols array:', updatedSymbols);
          return;
        }

        // Dispatch update action with correct API call
        dispatch(updateWatchlistAction({ listId, symbols: updatedSymbols }))
          .then((response) => {
            if (response.meta?.requestStatus === 'fulfilled') {
              console.log('Watchlist updated successfully:', response.payload);
            } else {
              console.error('Failed to update watchlist:', response.error);
            }
          })
          .catch((error) => {
            console.error('Error updating watchlist:', error);
          });
      });

      // Handle active list change
      watchlistApi.onActiveListChanged().subscribe(null, () => {
        console.log('Active list changed');
        const activeListId = watchlistApi.getActiveListId();
        const currentListItems = watchlistApi.getList(activeListId);
        console.log('Active list items:', currentListItems);
      });

      // Handle list rename
      watchlistApi.onListRenamed().subscribe(null, ({ listId, newName }) => {
        console.log('List renamed:', listId, 'New name:', newName);
        // Update Redux state if necessary
      });

      watchlistApi.onListRemoved().subscribe(null, async (listId) => {
        console.log('🚀 Watchlist removed event triggered for:', listId);

        // ✅ Switch to a different watchlist before deleting
        const allLists = watchlistApi.getAllLists();
        console.log('📌 All watchlists:', allLists);

        // ✅ Ensure there is another watchlist to switch to
        const defaultList = allLists.defaultList(); // Get TradingView's default watchlist
        if (defaultList && defaultList !== listId) {
          console.log(`🔄 Switching to default watchlist before deleting: ${defaultList}`);
          watchlistApi.setActiveList(defaultList); // Switch to avoid TradingView crash
        }

        try {
          console.log(`🗑️ Attempting to delete watchlist: ${listId}`);
          watchlistApi.deleteList(listId); // This should now work without error
          console.log(`✅ Watchlist ${listId} successfully removed from TradingView`);
        } catch (error) {
          console.error('❌ Error removing watchlist from TradingView:', error);
        }

        // ✅ Now delete from backend API
        dispatch(deleteWatchlistAction(listId)).then((response) => {
          if (response.meta.requestStatus === 'fulfilled') {
            console.log('✅ Successfully deleted watchlist from backend with ID:', listId);
          } else {
            console.error('❌ Failed to delete watchlist from backend:', response.error);
          }
        });
      });

      // watchlistApi.onListRemoved().subscribe(null, async (listId) => {
      //   console.log('Watchlist removed event triggered for:', listId);

      //   // try {
      //   //   // ✅ Manually remove the watchlist from TradingView UI
      //   //   if (watchlistApi.getAllLists()?.[listId]) {
      //   //     console.log(`Deleting watchlist from TradingView UI: ${listId}`);
      //   //     watchlistApi.deleteList(listId);
      //   //   } else {
      //   //     console.warn(`Watchlist ${listId} not found in TradingView`);
      //   //   }
      //   // } catch (error) {
      //   //   console.error('Error removing watchlist from TradingView:', error);
      //   // }

      //   // ✅ Call API to delete the watchlist from the backend
      //   // dispatch(deleteWatchlistAction(listId)).then((response) => {
      //   //   if (response.meta.requestStatus === 'fulfilled') {
      //   //     console.log('Successfully deleted watchlist from backend with ID:', listId);
      //   //   } else {
      //   //     console.error('Failed to delete watchlist from backend:', response.error);
      //   //   }
      //   // });
      // });
    });

    tvWidget.onChartReady(async () => {
      // const watchlistApi = await tvWidget.i;
      tvWidget.subscribe('study_event', (entityId, studyEventType, entity) => {
        // console.log('Indicator added 1111:', entityId, studyEventType);
        // console.log('Indicator added 1111:', entity);
        // Here, you could also set up a custom handler for user clicks on the overlay element.
      });
    });

    return () => {
      tvWidget.remove();
    };
  }, [
    props.symbol,
    currentSymbol,
    props.datafeedUrl,
    props.headers,
    props.disabled_features,
    props.chartsStorageUrl,
    props.chartsStorageApiVersion,
    props.clientId,
    props.userId,
    props.fullscreen,
    props.autosize,
    props.studiesOverrides,
    props.widgetbar,
    props.drawings_access,
    props.studies_access,
    props.theme,
    dispatch,
    watchlistData,
    props.enabled_features,
  ]);

  return <div ref={chartContainerRef} className={'TVChartContainer'} />;
};
